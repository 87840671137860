
.cursor-pointer:hover {
  cursor: pointer;
}

.mapboxgl-ctrl-bottom-left,
.mapboxgl-ctrl-bottom-right {
  bottom: 4.75rem !important;
}
.mapboxgl-ctrl-logo {
  margin: 0 0 -5px -2px !important;
}
